<template lang="">
    <div class="sign-online-container">
        <div
            v-if="!isEnrolled"
            class="help-section"
            @click="showEnrollmentDetails">
            <i class="fas fa-info-circle"></i>
            <p class="signature-help-text">
                {{ $t("APPLICATION.SIGNATURE.ONLINE.INFO_TEXT") }}
            </p>
        </div>
        <div class="sign-details-section">
            <div class="sign-summary">
                <div class="sign-summary-items-title">
                    {{ $t("APPLICATION.SIGNATURE.ONLINE.SUMMARY_DIGITAL_SIGNATURE_LABEL")}}
                </div>
                <div class="sign-summary-item">
                    {{$t("APPLICATION.SIGNATURE.ONLINE.SUMMARY_REQUIRED_SIGNATURES")}}:
                    <span class="text-danger">{{ requiredSignatures }}</span>
                </div>
                <div class="sign-summary-item d-none">
                    {{$t("APPLICATION.SIGNATURE.ONLINE.SUMMARY_AVAILABLE_SIGNATURES")}}:
                    <span class="text-success">{{ availableSignatures }}</span>
                </div>
                <div class="sign-summary-item d-none">
                    {{$t("APPLICATION.SIGNATURE.ONLINE.SUMMARY_LEFTOVER_SIGNATURES")}}:
                    <span class="text-success">{{ leftSignatures }}</span>
                </div>
            </div>
            <div class="package-details row" v-if="!hasEnoughSignatures">
                <div class="col col-md-6 package-form-group">
                    <div class="package-label">
                        {{ $t("APPLICATION.SIGNATURE.ONLINE.PACKAGE_LABEL") }}
                    </div>
                    <b-form-select
                        v-model="signPackage"
                        value-field="id"
                        text-field="name"
                        ref="package"
                        name="package"
                        :disabled="viewMode"
                        @change="updateSignatures"
                    >
                        <b-form-select-option
                            v-for="item in certSignPackages"
                            :key="item.id"
                            :value="item.id"
                            >{{
                                `${item.name} - ${item.price_including_vat} lei`
                            }}</b-form-select-option
                        >
                    </b-form-select>
                </div>
            </div>
        </div>
        <enrollment-details-modal ref="enrollmentDetailsModal"></enrollment-details-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import EnrollmentDetailsModal from "@/modules/applications/components/signature/EnrollmentDetailsModal";

export default {
    name: "SignatureOnline",
    props: {
        signDetails: {
            type: Object,
            required: false
        },
        viewMode: {
          type: Boolean,
          required: false,
      },
    },
    components: {
        EnrollmentDetailsModal,
    },
    data() {
        return {
            logoCertSign: `${process.env.BASE_URL}media/logos/logo-CS.png`,
            requiredSignatures: 1,
            availableSignatures: 0,
            leftSignatures: 0,
            signPackage: "",
            hasEnoughSignatures: false,
        };
    },
    computed: {
        ...mapGetters({
            appConfig: "shared/appConfig",
            currentUser: "auth/currentUser",
        }),
        certSignPackages() {
            return this.appConfig.CERTSIGN_SIGNING_PACKAGES.filter((el) => !el.is_bundle);
        },
    },
    methods: {
        updateSignatures(value) {
            if (!value) {
                this.leftSignatures = 0;
                this.availableSignatures = 0;
            }
            const selected = this.certSignPackages.find((el) => el.id === value);
            // Pay as you go
            if (!selected.is_bundle) {
                this.availableSignatures = this.requiredSignatures;
                this.leftSignatures = 0;
                selected.required_signatures = this.requiredSignatures;
                selected.total_price = selected.price_including_vat * this.requiredSignatures;
            } else { // bundle
                this.availableSignatures = selected.quantity;
                const remaining = selected.quantity - this.requiredSignatures;
                this.leftSignatures = remaining >= 0 ? remaining : 0;
                selected.total_price = selected.price_including_vat;
            }
            this.$emit("update", selected);
        },
        showEnrollmentDetails() {
            this.$refs.enrollmentDetailsModal.show();
        },
        isEnrolled() {
            return this.currentUser.enrolled;
        },
    },
    created(){
        if (this.signDetails && this.signDetails?.package) {
            this.signPackage = this.signDetails?.package.id || '';
            this.updateSignatures(this.signPackage);
        } else {
            const payAsYouGoPackage = this.certSignPackages.find((el) => !el.is_bundle);
            if (payAsYouGoPackage) {
                this.signPackage = payAsYouGoPackage.id;
                this.updateSignatures(this.signPackage);
            }
        }
        this.availableSignatures = this.currentUser.remaining_signatures;
        const leftTotal = this.availableSignatures - this.requiredSignatures;
        this.leftSignatures = leftTotal > 0  ? leftTotal : 0;
        this.hasEnoughSignatures = this.availableSignatures - this.requiredSignatures >= 0;
        // If the user does not have enough signatures we need to validate if we have a selected signature package
        this.$emit('validatePackage', !this.hasEnoughSignatures);
    }
};
</script>
