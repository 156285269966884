<template>
    <div>
        <st-modal
            id="enrollment-modal"
            hideable
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5">{{ userFullName }}</p>
            </template>
            <template #body>
                <div class="d-flex mb-4">
                    <div>
                        <div class="font-weight-bold">{{ $t('APPLICATION.ENROLLMENT_DETAILS.CITIZENSHIP.HEADER') }}:</div>
                        <div>{{ citizenType }}</div>
                    </div>
                    <div class="ml-4">
                        <div class="font-weight-bold">{{ $t('APPLICATION.ENROLLMENT_DETAILS.PERSON.HEADER') }}:</div>
                        <div>{{ userType }}</div>
                    </div>
                </div>
                <div class="mb-4">
                    <span class="font-weight-bold">{{ $t('APPLICATION.ENROLLMENT_DETAILS.PERSONAL_NUMERIC_CODE') }}:</span>
                    <span class="ml-2">{{ currentUser.personal_numeric_code }}</span>
                </div>
                <div class="mb-4">
                    <span class="font-weight-bold">{{ $t('APPLICATION.ENROLLMENT_DETAILS.ADDRESS') }}:</span>
                    <span class="ml-2">{{ userAddress }}</span>
                </div>
                <div class="mb-4">
                    <div class="font-weight-bold">{{ $t('APPLICATION.ENROLLMENT_DETAILS.CONTACT') }}:</div>
                    <div>{{ currentUser.phone_number }}</div>
                    <div>{{ currentUser.email }}</div>
                </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        :callback="hide"
                    > {{ $t('GENERAL.PREV') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'EnrollmentDetailsModal',
    props: {
        item: {
            type: Object,
            required: false
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
        }),
        userFullName() {
            return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
        },
        citizenType() {
            if (this.currentUser.user_type_name === 'foreigner') {
                return this.$t('APPLICATION.ENROLLMENT_DETAILS.CITIZENSHIP.FOREIGNER');
            }
            return this.$t('APPLICATION.ENROLLMENT_DETAILS.CITIZENSHIP.ROMANIAN');
        },
        userType() {
            if (this.currentUser.user_type_name === 'legal_entity') {
                return this.$t('APPLICATION.ENROLLMENT_DETAILS.PERSON.LEGAL');
            }
            return this.$t('APPLICATION.ENROLLMENT_DETAILS.PERSON.INDIVIDUAL');
        },
        userAddress() {
            let address = '';
            if (this.currentUser.county) address += `${this.$t('APPLICATION.ENROLLMENT_DETAILS.ADDRESS_COUNTY')} ${this.currentUser.county}`;
            if (this.currentUser.country) address += this.currentUser.country;
            if (this.currentUser.locality) address +=  `, ${this.currentUser.locality}`;
            if (this.currentUser.street) address += `, ${this.$t('APPLICATION.ENROLLMENT_DETAILS.ADDRESS_STREET')} ${this.currentUser.street}`;
            if (this.currentUser.street_number) address += `, ${this.$t('APPLICATION.ENROLLMENT_DETAILS.ADDRESS_NUMBER')} ${this.currentUser.street_number}`;
            if (this.currentUser.staircase) address += `, ${this.$t('APPLICATION.ENROLLMENT_DETAILS.ADDRESS_STAIRCASE')} ${this.currentUser.staircase}`;
            if (this.currentUser.apartment) address += `, ${this.$t('APPLICATION.ENROLLMENT_DETAILS.ADDRESS_APARTMENT')} ${this.currentUser.apartment}`;
            return address;
        },
    },
    methods: {
        hide() {
            this.$refs['modal'].hide();
        },
        show() {
            this.$refs['modal'].show();
        },
    }
};
</script>
